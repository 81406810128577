<template>
  <div class="invoice-container">
    <img class="invoice-qrcode" src="@/assets/invoice-qrcode.jpg" alt="" />
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$setTitle("申请发票");
    this.$store.commit("setTitle", "申请发票");
  },
};
</script>

<style>
body {
  background-color: #f4f4f4 !important;
}
</style>

<style scoped>
.invoice-container {
  padding: 15px;
  background-color: #f4f4f4;
}
.invoice-qrcode {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}
</style>